import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "core-js/modules/es.array.push.js";
/*
* @Author: Vincent
* @Date:   2021-07-13 14:21:22
* @Last Modified by:   Vincent
* @Last Modified time: 2021-07-22 14:30:12
*/

import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import { Base64 } from 'js-base64';
import store from '@/store';

// 是否有请求再刷新token
window.isRefreshing = false;
// 被挂起的请求数组
let subscribesArr = [];
const httpServer = opts => {
  const publicParams = {
    ts: Date.now()
  };
  const method = opts.method.toUpperCase();
  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_API_PREFIX}`,
    timeout: 30000,
    responseType: opts.responseType || 'json'
  });
  instance.interceptors.request.use(config => {
    const isToken = config.headers['X-isToken'] === false ? config.headers['X-isToken'] : true;
    const token = store.getters.token && store.getters.token.token;
    const clientId = process.env.VUE_APP_CLIENT_ID;
    const clientSecret = process.env.VUE_APP_CLIENT_SECRET;
    config.headers['Authorization'] = `Basic ${Base64.encode(`${clientId}:${clientSecret}`)}`;
    if (token && isToken) {
      config.headers.token = 'Bearer ' + token;
      if (isTokenExpired()) {
        // 如果token已经过期，尝试用refreshToken去刷新token
        if (!window.isRefreshing) {
          window.isRefreshing = true;
          refreshToken().then(response => {
            window.isRefreshing = false;
            const res = response.data;
            if (res.isSuccess) {
              const {
                data
              } = res;
              store.commit('account/setToken', _.pick(data, ['token', 'refreshToken', 'expiration']));

              // 重新发起挂起的请求
              reloadSubscribesArr(data.token);
            } else {
              subscribesArr = [];
              directLogin();
            }
          }).catch(() => {
            window.isRefreshing = false;
            subscribesArr = [];
            directLogin();
          });
        }

        // 如果token已经过期，挂起请求
        const retry = new Promise(resolve => {
          subscribeTokenRefresh(newToken => {
            config.headers.token = 'Bearer ' + newToken;
            resolve(config);
          });
        });
        return retry;
      }
    }
    return config;
  }, error => {
    return Promise.reject(error);
  });

  // 接口返回处理
  instance.interceptors.response.use(response => {
    return response;
  }, error => {
    return Promise.reject(error);
  });
  const httpDefaultOpts = {
    method,
    url: opts.url,
    isHiddenLoading: false || opts.isHiddenLoading !== undefined && opts.isHiddenLoading
  };
  if (opts['meta']) {
    httpDefaultOpts.headers = opts['meta'];
  }
  const dataRequest = ['PUT', 'POST', 'PATCH'];
  if (dataRequest.includes(method)) {
    httpDefaultOpts.data = opts.data || {};
  } else {
    httpDefaultOpts.params = {
      ...publicParams,
      ...(opts.data || {})
    };
  }

  // formData转换
  if (opts.formData) {
    httpDefaultOpts.transformRequest = [data => {
      if (data instanceof FormData) {
        return data;
      }
      const formData = new FormData();
      if (data) {
        Object.entries(data).forEach(item => {
          formData.append(item[0], item[1]);
        });
      }
      return formData;
    }];
  }
  if (opts.isShowProgress) {
    httpDefaultOpts.onUploadProgress = opts.onUploadProgress;
  }
  if (!httpDefaultOpts.isHiddenLoading) {
    _Toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true
    });
  }
  const promise = new Promise((resolve, reject) => {
    instance(httpDefaultOpts).then(response => {
      if (!httpDefaultOpts.isHiddenLoading) {
        _Toast.clear();
      }
      handleSuccess(response, resolve, opts);
    }).catch(error => {
      if (!httpDefaultOpts.isHiddenLoading) {
        _Toast.clear();
      }
      handleError(error, reject, opts);
    });
  });
  return promise;
};
const refreshService = axios.create({
  baseURL: `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_API_PREFIX}`,
  timeout: 30000,
  responseType: 'json'
});
function refreshToken() {
  const refreshToken = store.getters.token && store.getters.token.refreshToken;
  const clientId = process.env.VUE_APP_CLIENT_ID;
  const clientSecret = process.env.VUE_APP_CLIENT_SECRET;
  return new Promise((resolve, reject) => {
    refreshService.post(`/authority/v2/users/login`, {
      refreshToken: refreshToken,
      grantType: 'refresh_token'
    }, {
      headers: {
        'Authorization': `Basic ${Base64.encode(`${clientId}:${clientSecret}`)}`
      }
    }).then(resolve, reject);
  });
}
function isTokenExpired() {
  let expiredTime = store.getters.token && store.getters.token.expiration || 0;
  if (expiredTime) {
    const nowTime = new Date().getTime();
    expiredTime = moment(expiredTime, 'YYYY-MM-DD HH:mm:ss').valueOf();
    return (expiredTime - nowTime) / 1000 < 30; //10 * 60
  }
  return false;
}
function handleError(error, reject, opts) {
  let isAlert = opts.custom ? opts.custom['isAlert'] : true;
  if (isAlert) {
    if (error.code === 'ECONNABORTED') {
      _Toast.fail('请求超时');
    } else if (error.response && error.response.data) {
      const msg = error.response.data.msg || '';
      _Toast.fail(msg);
    } else if (error.msg) {
      _Toast.fail(error.msg);
    }
  }
  reject(error);
}
function handleSuccess(res, resolve, opts) {
  let isAlert = opts.custom ? opts.custom['isAlert'] : true;
  if (res.data && res.data.isError) {
    // 未登录
    if (res.data.code === 40000 || res.data.code === 40001 || res.data.code === 40002 || res.data.code === 40003 || res.data.code === 40005 || res.data.code === 40006 || res.data.code === 40008) {
      _Dialog.alert({
        title: '请先登录!'
      }).then(() => {
        directLogin();
      });
    } else {
      if (res.data.code === 40163) {
        isAlert = false;
      }
      if (isAlert) {
        if (res.data.code !== 1000 && res.data.code !== 1004) {
          _Toast.fail(res.msg || res.data && res.data.msg);
        }
      }
    }
  }
  resolve(res);
}

// push所有请求到数组中
function subscribeTokenRefresh(cb) {
  subscribesArr.push(cb);
}

// 用新的token发起请求
function reloadSubscribesArr(token) {
  subscribesArr.map(cb => cb(token));
}

// 重定向到login页面
function directLogin() {
  store.commit('account/setToken', {});
  // TODO: 情况User缓存
  // store.commit('account/setUser', {})

  window.location.hash = '/auth/login';
}
export default httpServer;