import _ from 'lodash';
import moment from 'moment';
const db = {
  save(key, value, expirationTime = {}) {
    let projectName = process.env.VUE_APP_PROJECT_NAME;
    let data = {
      value
    };
    if (!_.isEmpty(expirationTime)) {
      data = _.assign(data, {
        expiredAt: moment().add(expirationTime).format('X')
      });
    }
    localStorage.setItem(projectName + "_" + key, JSON.stringify(data));
  },
  get(key, defaultValue = {}) {
    let projectName = process.env.VUE_APP_PROJECT_NAME;
    try {
      const data = JSON.parse(localStorage.getItem(projectName + "_" + key)) || defaultValue;

      // 兼容老版本localstorage
      if (_.isObject(data)) {
        if (data && data.expiredAt) {
          const currentTimestamp = moment().format('X');
          if (currentTimestamp > data.expiredAt) {
            localStorage.removeItem(projectName + '_' + key);
            return null;
          } else {
            return data.value;
          }
        } else {
          return data.value;
        }
      } else {
        return data;
      }
    } catch (err) {
      return defaultValue;
    }
  },
  remove(key) {
    let projectName = process.env.VUE_APP_PROJECT_NAME;
    localStorage.removeItem(projectName + "_" + key);
  },
  clear() {
    let projectName = process.env.VUE_APP_PROJECT_NAME;
    let projectOpsName = process.env.VUE_APP_PROJECT_OPS_NAME;
    for (const key in localStorage) {
      if (key.indexOf(projectName) !== -1) {
        localStorage.removeItem(key);
      } else {
        // 删除既不属于OPS又不属于hospital的localstorage
        if (key.indexOf(projectOpsName) < 0) {
          localStorage.removeItem(key);
        }
      }
    }
  }
};
export default db;