import _ from 'lodash';
import moment from 'moment';

/*
 * 隐藏用户手机号中间四位
 */
export function hidePhone(phone) {
  return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
}
export function studyTimeFormat(studyTime) {
  let formattedStudyTime;
  try {
    if (_.includes(studyTime, '-')) {
      formattedStudyTime = moment(studyTime, 'YYYYMMDD-HHmmss').format('YYYY-MM-DD HH:mm:ss');
    } else {
      formattedStudyTime = moment(studyTime, 'YYYYMMDD').format('YYYY-MM-DD');
    }
  } catch (err) {
    formattedStudyTime = studyTime;
  }
  formattedStudyTime = moment(formattedStudyTime, true).isValid() ? formattedStudyTime : studyTime;
  return formattedStudyTime;
}
export function formatDateTime(date, format = 'YYYY-MM-DD HH:mm') {
  let formatedDate = moment(date, true).isValid() ? moment(date).format(format) : date;
  return formatedDate;
}