/*
* @Author: Vincent
* @Date:   2021-07-13 11:36:09
* @Last Modified by:   vincent
* @Last Modified time: 2021-07-19 18:53:20
*/

import db from '@/store/localstorage';
export default {
  namespaced: true,
  state: {
    targetUrl: db.get('TARGET_URL') || '',
    searchContent: db.get('SEARCH_CONTENT') || '',
    tenant: db.get('TENANT') || {},
    isHasFes: db.get('HAS_FES') || false
  },
  mutations: {
    setTargetUrl(state, val) {
      db.save('TARGET_URL', val);
      state.targetUrl = val;
    },
    setSearchContent(state, val) {
      db.save('SEARCH_CONTENT', val);
      state.searchContent = val;
    },
    setTenant(state, val) {
      db.save('TENANT', val);
      state.tenant = val;
    },
    setIsHasFes(state, val) {
      db.save('HAS_FES', val);
      state.isHasFes = val;
    }
  }
};