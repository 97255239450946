import Vue from 'vue';
import _ from 'lodash';
import Router from 'vue-router';
import { judgeClient, isInWechat } from '@/utils/common';
import store from '@/store/index';
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
Vue.use(Router);
const constRouter = [{
  path: '/',
  name: 'index',
  redirect: () => {
    return '/case/building';
  }
}, {
  path: '/s/:code',
  name: 'ShortUrl',
  component: () => import('views/shortUrl')
}, {
  path: '/xgPlayer',
  name: 'XgPlayer',
  component: () => import('views/xgPlayer')
}, {
  path: '/wechat',
  name: 'WechatMp',
  component: () => import('views/wechat')
}, {
  path: '/auth',
  name: 'Auth',
  component: () => import('views/layouts/index'),
  meta: {
    title: '注册',
    keepAlive: false
  },
  children: [{
    path: 'login',
    name: 'Login',
    component: () => import('@/views/auth/login/index'),
    meta: {
      title: '登录',
      keepAlive: false
    }
  }, {
    path: 'register',
    name: 'AuthRegister',
    component: () => import('@/views/auth/register/index'),
    meta: {
      title: '注册',
      keepAlive: false
    }
  }, {
    path: 'password',
    name: 'AuthPassword',
    component: () => import('@/views/auth/password/index'),
    meta: {
      title: '登录',
      keepAlive: false
    }
  }, {
    path: 'info',
    name: 'UserInfo',
    component: () => import('@/views/auth/userInfo/index'),
    meta: {
      title: '用户信息',
      keepAlive: false
    }
  }]
}, {
  path: '/cases',
  name: 'Cases',
  component: () => import('@/views/layouts'),
  children: [{
    path: '',
    name: 'CaseList2',
    component: () => import('@/views/cases'),
    meta: {
      title: '患者病案',
      keepAlive: true,
      showTabBar: true
    }
  }, {
    path: 'operate',
    name: 'CaseOperate2',
    component: () => import('@/views/cases/components/CaseOperateTemplate'),
    meta: {
      keepAlive: false,
      showTabBar: false
    }
  }, {
    path: 'emergency-case-pay-success',
    name: 'EmergencyCasePaySuccess',
    component: () => import('@/views/cases/components/PaySuccess'),
    meta: {
      title: '卒中急救卡购买成功',
      keepAlive: false
    }
  }, {
    path: 'case-service-pay-success',
    name: 'CaseServicePaySuccess',
    component: () => import('@/views/cases/components/PaySuccess'),
    meta: {
      title: '服务费支付成功',
      keepAlive: false
    }
  }, {
    path: ':caseId',
    name: 'CaseDetail2',
    component: () => import('@/views/cases/components/CaseTemplate'),
    meta: {
      title: '病案详情',
      keepAlive: false
    }
  }, {
    path: ':caseId/buyCaseService',
    name: 'BuyCaseService',
    component: () => import('@/views/cases/buyCaseService'),
    meta: {
      title: 'VIP档案服务',
      keepAlive: false
    }
  }, {
    path: ':caseId/emergencyCard',
    name: 'BuyEmergencyCard',
    component: () => import('@/views/cases/buyEmergencyCard'),
    meta: {
      title: '实体档案卡',
      keepAlive: false
    }
  }]
}, {
  path: '/consultations',
  name: 'Consultations',
  component: () => import('@/views/layouts'),
  children: [{
    path: '',
    name: 'ConsultationList',
    component: () => import('@/views/consultations'),
    meta: {
      title: '咨询列表',
      keepAlive: false,
      showTabBar: true
    }
  }, {
    path: ':consultationId',
    name: 'ConsultationDetail',
    component: () => import('@/views/consultations/components/ConsultationDetailTemplate'),
    meta: {
      title: '咨询详情',
      keepAlive: false
    }
  }, {
    path: ':caseId/doctors',
    name: 'ConsultationDoctors',
    component: () => import('@/views/consultations/components/ConsultationDoctorListTemplate'),
    meta: {
      title: '随访医生',
      keepAlive: false
    }
  }, {
    path: ':caseId/doctors/:doctorId/confirm',
    name: 'ConsultationConfirm',
    component: () => import('@/views/consultations/components/ConsultationConfirmTemplate'),
    meta: {
      title: '医生主页',
      keepAlive: false
    }
  }]
}, {
  path: '/mine',
  name: 'Mine',
  component: () => import('views/layouts/index'),
  children: [{
    path: '',
    name: 'MineList',
    component: () => import('views/mine/index'),
    meta: {
      title: '个人中心',
      keepAlive: false,
      showTabBar: true
    }
  }]
}, {
  path: '/address',
  name: 'Address',
  component: () => import('views/layouts/index'),
  meta: {
    title: '',
    keepAlive: false
  },
  children: [{
    path: 'list',
    name: 'AddressList',
    component: () => import('@/views/address/index'),
    meta: {
      title: '我的收获地址',
      keepAlive: false
    }
  }, {
    path: 'operate',
    name: 'AddressOperate',
    component: () => import('@/views/address/operate'),
    meta: {
      title: '地址操作',
      keepAlive: false
    }
  }]
}, {
  path: '/case',
  name: 'Case',
  component: () => import('views/layouts/index'),
  meta: {
    title: '建档',
    keepAlive: false
  },
  children: [{
    path: 'list',
    name: 'CaseList',
    component: () => import('@/views/case/list/index'),
    meta: {
      title: '病案列表',
      keepAlive: false
    }
  }, {
    path: 'detail',
    name: 'CaseDetail',
    component: () => import('@/views/case/detail/index'),
    meta: {
      title: '病案详情',
      keepAlive: false
    }
  }, {
    path: 'operate',
    name: 'CaseOperate',
    component: () => import('@/views/case/operate/index'),
    meta: {
      title: '病案操作',
      keepAlive: false
    }
  }, {
    path: 'building',
    name: 'CaseBuilding',
    component: () => import('@/views/case/building/index'),
    meta: {
      title: '建档',
      keepAlive: false
    }
  }, {
    path: 'pay',
    name: 'CasePay',
    component: () => import('@/views/case/pay/index'),
    meta: {
      title: '建档支付',
      keepAlive: false
    }
  }, {
    path: 'authorize',
    name: 'CaseAuthorize',
    component: () => import('@/views/case/authorize/index'),
    meta: {
      title: '档案授权',
      keepAlive: false
    }
  }, {
    path: 'paySuccess',
    name: 'CasePaySuccess',
    component: () => import('@/views/case/pay/success'),
    meta: {
      title: '建档支付成功',
      keepAlive: false
    }
  }, {
    path: 'wechat',
    name: 'CaseWechat',
    component: () => import('@/views/case/wechat'),
    meta: {
      title: '档案详情',
      keepAlive: false
    }
  }]
}, {
  path: '/questionnaires',
  name: 'Questionnaire',
  component: () => import('@/views/layouts'),
  meta: {
    title: '问卷',
    keepAlive: false
  },
  children: [{
    path: 'v1/:questionnaireId',
    name: 'QuestionnaireV1',
    component: () => import('@/views/questionnaires/questionnaireV1'),
    meta: {
      title: '随访问卷',
      keepAlive: false
    }
  }, {
    path: 'qinstancelist',
    name: 'QuestionnaireInstanceList',
    component: () => import('@/views/questionnaires/qInstanceList'),
    meta: {
      title: '医生随访',
      keepAlive: false
    }
  }, {
    // WARNING：fillout路径不能更改，后端生成的问卷二维码内含此路径名称
    path: 'fillout',
    name: 'QuestionnaireFillout',
    component: () => import('@/views/questionnaires/fillout'),
    meta: {
      title: '问卷填写',
      keepAlive: false
    }
  }, {
    path: 'case/:questionnaireId',
    name: 'QuestionnaireCaseFillout',
    component: () => import('@/views/questionnaires/caseFillout'),
    meta: {
      title: '病案随访',
      keepAlive: false
    }
  }, {
    path: 'instance/:instanceId',
    name: 'QuestionnaireInstanceTemplate',
    component: () => import('@/views/questionnaires/instance'),
    meta: {
      title: '答卷详情',
      keepAlive: false
    }
  }]
}, {
  path: '/questions',
  name: 'Questions',
  component: () => import('@/views/layouts'),
  meta: {
    title: '问卷',
    keepAlive: false
  },
  children: [{
    path: '',
    name: 'QuestionEdit',
    component: () => import('@/views/question/index'),
    meta: {
      title: '问卷模板',
      keepAlive: false
    }
  }, {
    path: 'view',
    name: 'QuestionView',
    component: () => import('@/views/question/view'),
    meta: {
      title: '答卷内容',
      keepAlive: false
    }
  }]
}, {
  path: '/invite',
  name: 'Invite',
  component: () => import('@/views/layouts'),
  meta: {
    title: '邀请',
    keepAlive: false
  },
  children: [{
    path: '/patient',
    name: 'InvitePatient',
    component: () => import('@/views/invite/index'),
    meta: {
      title: '邀请患者',
      keepAlive: false
    }
  }, {
    path: 'poster',
    name: 'InvitePoster',
    component: () => import('@/views/invite/poster'),
    meta: {
      title: '邀请海报',
      keepAlive: false
    }
  }]
}, {
  path: '/partner',
  name: 'Patient',
  component: () => import('@/views/layouts'),
  meta: {
    title: '',
    keepAlive: false
  },
  children: [{
    path: '',
    name: 'PartnerIndex',
    component: () => import('@/views/partner/index'),
    meta: {
      title: '',
      keepAlive: false
    }
  }, {
    path: 'login',
    name: 'PartnerLogin',
    component: () => import('@/views/partner/login/index'),
    meta: {
      title: '',
      keepAlive: false
    }
  }, {
    path: 'register',
    name: 'PartnerRegister',
    component: () => import('@/views/partner/register/index'),
    meta: {
      title: '',
      keepAlive: false
    }
  }, {
    path: 'patient',
    name: 'PartnerPatient',
    component: () => import('@/views/partner/patient/index'),
    meta: {
      title: '',
      keepAlive: false
    }
  }, {
    path: 'pay',
    name: 'PartnerPay',
    component: () => import('@/views/partner/pay/index'),
    meta: {
      title: '',
      keepAlive: false
    }
  }, {
    path: 'pay/success',
    name: 'PartnerPaySuccess',
    component: () => import('@/views/partner/pay/success'),
    meta: {
      title: '',
      keepAlive: false
    }
  }]
}];
const createRouter = () => {
  return new Router({
    scrollBehavior: () => ({
      y: 0
    }),
    routes: constRouter
  });
};
const router = createRouter();
const whiteList = ['/auth/login', '/auth/password', '/auth/register', '/case/building', '/wechat', '/questions', '/questions/view', '/partner'
// '/partner/login',
// '/partner/patient',
// '/partner/patient/pay'
// '/questionnaires/fillout'
// '/case/pay'
// '/cases/case-service-pay-success',
// '/case/paySuccess'
];
router.beforeEach((to, from, next) => {
  let client = judgeClient();
  if (!isInWechat() && client !== 'PC') {
    if (to.path !== '/wechat') {
      return next({
        path: '/wechat',
        replace: true
      });
    } else {
      return next();
    }
  }
  if (client === 'IOS') {
    let signUrl = location.href.split(' ')[0];
    store.commit('signature/setSignUrl', signUrl);
  }
  // debugger
  if (whiteList.indexOf(to.path) !== -1 || to.path.startsWith('/s/')) {
    // debugger
    return next();
  }

  // // 测试用, 提交前删除!
  // store.commit('account/setToken', {
  //   token: 'eyJ0eXAiOiJKc29uV2ViVG9rZW4iLCJhbGciOiJIUzI1NiJ9.eyJuYW1lIjoi5Lie55u45aSn5Lq6IiwidG9rZW5fdHlwZSI6InRva2VuIiwidXNlcmlkIjoiMTI5Mzc1NDgxOTY1NTIzNzYzMiIsImFjY291bnQiOiIxNTA2NzQzNjQ1NSIsInRlbmFudCI6IlAwMDAwMDEiLCJhY2NvdW50X3BhcnQiOiJURU5BTlRfVVNFUiIsImV4cCI6MTY0NDUxMzYwMCwibmJmIjoxNjQ0NDcwNDAwfQ.v6TyyzrKdb0AC1Fgc-2TivjB5yzaMHAf-Ba2ZBY9MCc',
  //   refreshToken: 'eyJ0eXAiOiJKc29uV2ViVG9rZW4iLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaF90b2tlbiIsInVzZXJpZCI6IjEyOTM3NTQ4MTk2NTUyMzc2MzIiLCJleHAiOjE2NDcwNjI0MDAsIm5iZiI6MTY0NDQ3MDQwMH0.h54GqcEh2DNrNl3KVQAINo2l069DM3WIem9xa8QXXc8',
  //   expiration: '2022-02-11 01:20:00'
  // })

  // store.commit('account/setUser', {
  //   userId: '1293754819655237632',
  //   account: '15067436455',
  //   name: '赵坑坑',
  //   avatar: 'http://thirdwx.qlogo.cn/mmopen/ibiabwB95peLz1icZswPp3KEffrP9QmpVUzNn9ZxkfOK7Jj9iagOiaUJXAc77CRDBTdLJRepXiaPcOA1z8W6vbtnZe9YtYMZrxCLVI/132'
  // })

  // store.commit('app/setTenant', {
  //   tenantCode: 'P000001',
  //   tenantName: '远程智像患者单位',
  //   tenantType: 'HOSPITAL'
  // })

  const token = store.getters.token && store.getters.token.token || {};
  if (_.isEmpty(token)) {
    const targetUrl = store.getters.targetUrl;
    if (_.isEmpty(targetUrl)) {
      store.commit('app/setTargetUrl', to.fullPath);
    }
    /**
     * added by YinJianFeng
     * 如果本地没有token，则调整微信授权页面
     */
    return next({
      path: '/auth/login',
      replace: true
    });

    // return next({ path: '/case/building', replace: true })
  } else {
    const targetUrl = store.getters.targetUrl;
    if (!_.isEmpty(targetUrl)) {
      store.commit('app/setTargetUrl', null);
      return next({
        path: targetUrl,
        replace: true
      });
    } else {
      return next();
    }
  }
});
export default router;