/*
* @Author: Vincent
* @Date:   2021-07-13 11:16:12
* @Last Modified by:   Vincent
* @Last Modified time: 2021-10-19 14:08:24
*/

import _ from 'lodash';
import DictionaryItemApiV1 from '@/apis/v1/DictionaryItem';
export function judgeClient() {
  const u = navigator.userAgent;
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
  const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  if (isAndroid) {
    return 'Android';
  } else if (isIOS) {
    return 'IOS';
  } else {
    return 'PC';
  }
}
export function isInWechat() {
  let ua = window.navigator.userAgent.toLowerCase();
  return ua.match(/MicroMessenger/i) == 'micromessenger' || ua.match(/_SQ_/i) == '_sq_';
}
export function randomNum(len, radix) {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  const uuid = [];
  radix = radix || chars.length;
  if (len) {
    for (let i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
  } else {
    let r;
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';
    for (let i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | Math.random() * 16;
        uuid[i] = chars[i === 19 ? r & 0x3 | 0x8 : r];
      }
    }
  }
  return uuid.join('') + new Date().getTime();
}
export const initDicts = (dicts = {}) => {
  let keys = _.keys(dicts) || [];
  return new Promise(resolve => {
    if (keys.length === 0) {
      return resolve({});
    }
    return DictionaryItemApiV1.getDictByKeys({
      codes: keys
    }).then(response => {
      const res = response.data;
      if (res.isSuccess) {
        _.map(keys, key => {
          dicts[key] = res.data[key] || {};
        });
        return resolve(_.cloneDeep(res.data));
      } else {
        return resolve({});
      }
    });
  });
};
export const formatCrossOriginUrl = url => {
  let rawUrl = _.cloneDeep(url);
  if (!_.isEmpty(rawUrl)) {
    rawUrl = _.replace(rawUrl, 'oss.stage.telezx.com', 'p.stage.telezx.com');
    rawUrl = _.replace(rawUrl, 'oss.telezx.com', 'p.telezx.com');
  }
  return rawUrl;
};