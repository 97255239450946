/*
* @Author: Vincent
* @Date:   2021-07-19 16:40:58
* @Last Modified by:   Vincent
* @Last Modified time: 2021-07-19 16:42:02
*/

import axiosApi from '../AxiosApi';
const API_PREFIX = '/authority/dictionaryItem';
const apis = {
  getDictByKeys: data => {
    return axiosApi({
      method: 'GET',
      url: `${API_PREFIX}/codes`,
      data
    });
  }
};
export default {
  ...apis
};