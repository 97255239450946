import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// 全局引入按需引入UI库 vant
import '@/plugins/vant';

// plugins
import { VantTelezxUploader } from '@/plugins/TelezxVantUploader';
import TelezxVantMediaPreviewer from '@/plugins/TelezxVantMediaPreviewer';
import TelezxVantCollapse from '@/plugins/TelezxVantCollapse';
import TelezxVantCollapseItem from '@/plugins/TelezxVantCollapseItem';
Vue.use(VantTelezxUploader);
Vue.use(TelezxVantMediaPreviewer);
Vue.use(TelezxVantCollapse);
Vue.use(TelezxVantCollapseItem);

// 引入全局样式
import '@/assets/css/index.scss';
import '@/plugins/TelezxVantUploader/index.scss';
import '@/plugins/TelezxVantMediaPreviewer/index.scss';
import '@/plugins/TelezxVantCollapseItem/index.scss';
import Vconsole from 'vconsole';
if (process.env.NODE_ENV === 'staging') {
  new Vconsole();
}

//移动端适配
import 'lib-flexible/flexible.js';
import './filters';
Vue.config.productionTip = false;
Vue.config.ignoredElements = ['wx-open-launch-weapp'];
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
});