import db from '@/store/localstorage';
export default {
  namespaced: true,
  state: {
    token: db.get('TOKEN') || {},
    user: db.get('USER') || {},
    channelId: db.get('CHANNEL_ID') || ''
  },
  mutations: {
    setToken(state, val) {
      db.save('TOKEN', val);
      state.token = val;
    },
    setUser(state, val) {
      db.save('USER', val);
      state.user = val;
    },
    setChannelId(state, val) {
      db.save('CHANNEL_ID', val);
      state.channelId = val;
    }
  }
};